<template>
    <div class="q-pa-md top-level row q-gutter-md justify-evenly items-stretch">

        <q-card class="my-card" v-for="(doc,idx) in docs" :key="'doc_'+idx">
            <q-card-section class="card-section-picture vertical-middle q-card__section q-card__section--vert fit row wrap justify-center items-center content-center" @click="openPopup(idx)">
                <img :src="doc.picture" style="width:auto; height:100px; padding:.5em;"/>
            </q-card-section>
            <q-card-section class="card-section-description">
                <div class="text-h6" v-html="doc.title"></div>
                <div class="text-subtitle2" v-html="doc.description" />
            </q-card-section>
        </q-card>

    </div>
</template>

<script>
export default ({
    name: 'Teacher',
    data() {
        return {
            docs: [
                {
                    picture: '/static/teacher/javascript.jpeg',
                    title: 'Javascript class',
                    description: 'JavaScript classes given as benevolent.',
                },
                {
                    picture: '/static/teacher/msoffice.png',
                    title: 'MS Office class',
                    description: 'MS-Word/MS-Excel class given as benevolent.',
                },
                {
                    picture: '/static/teacher/lecturer.jpg',
                    title: 'Speaker',
                    description: 'Lectures given at the TeamQuest Int\'l Symposium and the SAS Belux Symposium.',
                },
                {
                    picture: '/static/teacher/eyerlogo_150x47.png',
                    title: 'Eyer/ExceptionRep class',
                    description: 'As part of software installation, I gave classes on configuration and utilization.',
                },
                {
                    picture: '/static/teacher/perl.jpeg',
                    title: 'Perl class',
                    description: 'Perl language classes given in the Hewlett-Packard training center, Brussels.',
                },
                {
                    picture: '/static/teacher/agile.jpeg',
                    title: 'Team leader',
                    description: 'During two years, project team leader, scrum-like methodology.',
                },
                {
                    picture: '/static/teacher/lesscouts.png',
                    title: 'Scouts unit leader',
                    description: 'During 8 years, benevolent as leader person for the local scouting org (100+ affiliates, 20+ animators).',
                },
            ],
        };
    },
})
</script>

<style scoped lang="less">
.my-card {
    max-width: 300px;
    min-width: 300px;
    color: #444;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    .card-section-picture {
        height: 150px;
        width: auto;
        flex:0 0 150px;
        text-align: center;
        overflow:hidden;
        padding: 0;
    }
    .card-section-description {
        flex: 1 1;
        background-color:#f0f0f0;
        border-top: 1px solid DarkGray;
        border-bottom: 1px solid DarkGray;
        .text-h6 {
            font-weight: bold;
        }
    }
    .q-card__actions {
        flex:0 0;
        .openUrl {
            text-decoration: none;
            color: #555;
            font-weight: bolder;
        }
    }
}
</style>
